@import '../mixins/responsive';

.formality {

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include respond-to('small') {
      flex-direction: row;
    }
  }

  &__text,
  &__form {
    flex: 1 1 50%;
  }

  &__form-list {
    display: flex;
    flex-direction: column;

    margin-top: 32px;

    @include respond-to('small') {
      margin-top: 0;
    }

    input {
      margin-bottom: 16px;

      @include respond-to('small') {
        margin-bottom: 22px;
      }

      @include respond-to('large') {
        margin-bottom: 34px;
      }
    }
  }

  .controls {
    justify-content: center;

    @include respond-to('small') {
      justify-content: flex-start;
    }
  }
}