
@use 'core/reset';
@use 'core/typography';
@use 'core/layout';

@use 'components/controls';

@use 'components/audio';
@use 'components/about';
@use 'components/appearance';
@use 'components/chat';
@use 'components/details';
@use 'components/formality';
@use 'components/home';
@use 'components/loader';
@use 'components/personality';
@use 'components/unity';