@import '../mixins/responsive';

@keyframes music-playing {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes music-off {
  to {
    transform: scaleY(1);
  }
}

.audio {
  bottom: 20px;
  cursor: pointer;
  left: 20px;
  position: absolute;
  width: 20px;
  height: 21px;
  z-index: 100;

  @include respond-to('small') {
    bottom: 25px;
    left: 33px;
  }

  path {
    fill: #fff;
  }

  #line_1 {
    animation: music-off 1.5s 1 ease-out;
  }

  #line_2 {
    animation: music-off 1.5s 1 ease-out;
  }

  #line_3 {
    animation: music-off 1.5s 1 ease-out;
  }

  &--playing {
    #line_1 {
      transform-origin: bottom center;
      animation: music-playing 1.6s infinite;
    }

    #line_2 {
      transform-origin: bottom center;
      animation: music-playing 1.6s infinite 0.4s;
    }

    #line_3 {
      transform-origin: bottom center;
      animation: music-playing 1.6s infinite 0.8s;
    }
  }
}