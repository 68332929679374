html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body, input {
  color: #fff;
  font-family: IBM Plex Mono, monospace;
}

p {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 28px;
}

@media (min-width: 767px) {
  p {
    margin-bottom: 48px;
    font-size: 34px;
    line-height: 44px;
  }
}

h1 {
  font-size: 82px;
}

@media (min-width: 767px) {
  h1 {
    font-size: 100px;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 164px;
  }
}

h3 {
  font-size: 44px;
  line-height: 48px;
}

@media (min-width: 767px) {
  h3 {
    font-size: 60px;
    line-height: 64px;
  }
}

@media (min-width: 1200px) {
  h3 {
    font-size: 78px;
    line-height: 84px;
  }
}

h5 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
}

@media (min-width: 767px) {
  h5 {
    margin-bottom: 28px;
    font-size: 22px;
    line-height: 29px;
  }
}

a:link, a:visited, a:hover, a:active {
  color: #fff;
}

html, body {
  min-height: -webkit-fill-available;
  width: 100%;
  background-color: #000;
  overflow: hidden;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
  min-height: -webkit-fill-available;
  position: relative;
}

section {
  min-height: -webkit-fill-available;
  padding: 18px;
  position: relative;
}

@media (min-width: 767px) {
  section {
    padding: 80px;
  }
}

@media (min-width: 1200px) {
  section {
    padding: 100px;
  }
}

section > h5:first-child {
  visibility: hidden;
}

@media (min-width: 767px) {
  section > h5:first-child {
    visibility: visible;
  }
}

#catgpt-app {
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.catgpt__wrapper {
  min-height: -webkit-fill-available;
  width: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.catgpt__video-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.catgpt__video-wrapper:after {
  content: "";
  width: 100%;
  height: 100vh;
  background-color: #0009;
  position: absolute;
  top: 0;
  left: 0;
}

.catgpt__video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: thin solid #fff;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  font-size: 22px;
  line-height: 29px;
  transition: all .2s ease-out;
  display: inline-flex;
}

.button:hover {
  color: #000;
  background-color: #fff;
}

.button--disabled {
  pointer-events: none;
  opacity: .6;
}

input[type="text"] {
  color: #999;
  background-color: #000;
  border: none;
  border-radius: 40px;
  outline: none;
  padding: 10px 0 10px 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 767px) {
  input[type="text"] {
    padding: 20px 0 20px 24px;
    font-size: 18px;
    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  input[type="text"] {
    padding: 22px 0 22px 28px;
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-height: 690px) and (min-width: 767px) {
  input[type="text"] {
    padding: 18px 0 18px 24px;
    font-size: 16px;
    line-height: 18px;
  }
}

@keyframes blink {
  0% {
    visibility: hidden;
  }

  49% {
    visibility: hidden;
  }

  50% {
    visibility: visible;
  }

  100% {
    visibility: visible;
  }
}

.blink {
  animation: 1s infinite blink;
}

.controls {
  justify-content: center;
  margin-top: 16px;
  display: flex;
}

@media (min-width: 767px) {
  .controls {
    justify-content: flex-start;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .controls {
    margin-top: 0;
  }
}

@keyframes music-playing {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes music-off {
  to {
    transform: scaleY(1);
  }
}

.audio {
  cursor: pointer;
  width: 20px;
  height: 21px;
  z-index: 100;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 767px) {
  .audio {
    bottom: 25px;
    left: 33px;
  }
}

.audio path {
  fill: #fff;
}

.audio #line_1, .audio #line_2, .audio #line_3 {
  animation: 1.5s ease-out music-off;
}

.audio--playing #line_1 {
  transform-origin: bottom;
  animation: 1.6s infinite music-playing;
}

.audio--playing #line_2 {
  transform-origin: bottom;
  animation: 1.6s .4s infinite music-playing;
}

.audio--playing #line_3 {
  transform-origin: bottom;
  animation: 1.6s .8s infinite music-playing;
}

.about {
  will-change: transform;
  background: #6400ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: transform .8s cubic-bezier(.65, 0, .35, 1);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(100vw, 0, 1px);
}

.about--open {
  transform: translate3d(0, 0, 1px);
}

@media (min-width: 1200px) {
  .about {
    display: block;
  }
}

.about__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (min-width: 767px) {
  .about__close {
    top: 60px;
    right: 70px;
  }
}

.about__cta {
  color: #fff;
  align-items: center;
  font-size: 17px;
  line-height: 22px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (min-width: 767px) {
  .about__cta {
    top: 80px;
    right: 80px;
  }
}

@media (min-width: 1200px) {
  .about__cta {
    top: 46px;
    right: 52px;
  }
}

.about__text {
  width: 80%;
  padding-left: 18px;
}

@media (min-width: 767px) {
  .about__text {
    width: 55%;
    padding: 80px;
  }
}

@media (min-width: 1200px) {
  .about__text {
    width: 55%;
    padding: 100px;
  }
}

.about__pda-logo {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 767px) {
  .about__pda-logo {
    height: 300px;
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .about__pda-logo {
    height: 491px;
    width: 491px;
  }
}

.appearance__text, .appearance__colors {
  flex: 50%;
}

.appearance__colors {
  flex-direction: column;
  margin: 32px 0;
  display: flex;
}

@media (min-width: 767px) {
  .appearance__colors {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .appearance__colors .controls {
    justify-content: center;
  }
}

.appearance__colors-list {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px;
  margin: 0 26px 26px;
  display: grid;
}

.appearance__swatch {
  box-sizing: content-box;
  cursor: pointer;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 4px solid #0000;
  border-radius: 50%;
  padding-bottom: 100%;
  position: relative;
}

.appearance__swatch svg {
  height: 33px;
  width: 33px;
  margin: 3%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 767px) {
  .appearance__swatch svg {
    margin: -3%;
  }
}

@media (min-width: 1200px) {
  .appearance__swatch svg {
    margin: 6%;
  }
}

.appearance__swatch--selected {
  border: 4px solid red;
}

.appearance__swatch--selected svg {
  display: block;
}

.appearance__swatch--black {
  background-image: url("color-black.8a6d61f0.png");
}

.appearance__swatch--black-white {
  background-image: url("color-black-white.be7a1c84.png");
}

.appearance__swatch--white {
  background-image: url("color-white.3c20a3b7.png");
}

.appearance__swatch--orange {
  background-image: url("color-orange.98ba88c7.png");
}

.appearance__swatch--brown {
  background-image: url("color-brown.e23fcb84.png");
}

.appearance__swatch--gray {
  background-image: url("color-gray.cf8678ae.png");
}

.appearance__wrapper {
  flex-direction: column;
  display: flex;
}

@media (min-width: 767px) {
  .appearance__wrapper {
    flex-direction: row;
  }
}

.chat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.chat--connected {
  justify-content: space-between;
}

.chat .formality__wrapper {
  width: 50vw;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 767px) {
  .chat .formality__wrapper {
    width: 35vw;
    margin: 0 auto;
  }
}

.chat .formality__wrapper svg {
  width: 100%;
  margin-bottom: 28px;
}

@media (min-width: 767px) {
  .chat .formality__wrapper svg {
    width: 100%;
    margin-bottom: 46px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.chat .formality__wrapper .chat__text {
  width: auto;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 767px) {
  .chat .formality__wrapper .chat__text {
    font-size: 20px;
    line-height: 30px;
  }
}

.chat__connect-error {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.chat__connect-error h3 {
  width: 50%;
  margin-bottom: 32px;
}

.chat__error {
  opacity: 0;
  justify-content: center;
  transition: opacity .2s ease-out;
  display: flex;
}

.chat__error p {
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 30px;
  margin: 0 auto 25px;
  padding: 12px 28px;
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
}

.chat__response {
  justify-content: center;
  margin-top: 60px;
  display: flex;
}

.chat__response-text {
  text-align: center;
  width: 60vw;
  font-size: 17px;
  line-height: 22px;
  display: block;
}

@media (min-width: 767px) {
  .chat__response-text {
    width: 46.3vw;
  }
}

.chat__text {
  width: 444px;
}

.chat__text h1 {
  margin-bottom: 44px;
  font-size: 150px;
  line-height: 150px;
}

.chat__text span {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.chat__text svg {
  width: 28px;
  margin-left: 4px;
  display: inline;
}

.chat .input__submit {
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  position: relative;
}

.chat .input__wrapper {
  height: 48px;
  width: 50px;
  opacity: 0;
  background: #000;
  border: 1px solid #666;
  border-radius: 30px;
  align-self: center;
  align-items: center;
  margin: 0 auto 60px;
  transition: width 1s ease-in-out .2s, opacity .4s ease-out;
  display: flex;
}

@media (min-width: 767px) {
  .chat .input__wrapper {
    bottom: 80px;
  }
}

.chat .input__wrapper input {
  background-color: #0000;
  border-radius: 0;
  flex: 1 0 auto;
  padding: 0 0 2px 24px;
  transition: border-left .5s ease-out .8s;
}

.chat .input__wrapper input::placeholder {
  transition: transform .5s ease-out 1s;
  transform: translateY(50px);
}

.chat .input__wrapper .heart-container {
  position: relative;
}

.chat .input__wrapper svg {
  opacity: 0;
  cursor: pointer;
  margin: 0 10px;
  transition: transform .5s ease-out 1s;
}

.chat .input__wrapper svg.animating {
  z-index: 10;
}

.chat .input__wrapper svg.animating circle {
  fill: #0000;
  stroke: #0000;
  stroke-width: 0;
}

.chat .input__wrapper svg.animating path {
  fill: red;
}

.chat .input__wrapper svg.active circle {
  fill: red;
  stroke: red;
}

.chat .input__wrapper svg circle {
  fill: #0000;
  stroke: #fff;
  stroke-width: 1px;
  transition: fill .3s ease-out, stroke .3s ease-out;
}

.chat .input__wrapper--connected {
  width: 90%;
  opacity: 1;
}

@media (min-width: 767px) {
  .chat .input__wrapper--connected {
    width: 66.52vw;
  }
}

.chat .input__wrapper--connected input::placeholder {
  transform: translateY(0);
}

.chat .input__wrapper--connected .input__char-count {
  font-size: 10px;
  line-height: 13px;
}

.chat .input__wrapper--connected svg {
  opacity: 1;
}

.details__wrapper {
  flex-direction: column;
  display: flex;
}

@media (min-width: 767px) {
  .details__wrapper {
    flex-direction: row;
  }
}

.details__text, .details__form {
  flex: 50%;
}

.details__form-list {
  flex-direction: column;
  margin-top: 20px;
  display: flex;
}

@media (min-width: 767px) {
  .details__form-list {
    margin-top: 0;
  }
}

.details__form-list input {
  margin-bottom: 4px;
}

@media (min-width: 767px) {
  .details__form-list input {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .details__form-list input {
    margin-bottom: 22px;
  }
}

@media screen and (max-height: 690px) and (min-width: 767px) {
  .details__form-list input {
    margin-bottom: 10px;
  }
}

.details__label {
  margin-bottom: 12px;
  margin-left: 20px;
  display: block;
}

@media screen and (max-height: 690px) and (min-width: 767px) {
  .details__label {
    font-size: 14px;
  }
}

.details__input {
  width: 100%;
}

.details .controls {
  justify-content: center;
}

@media (min-width: 767px) {
  .details .controls {
    justify-content: flex-start;
  }
}

.formality__wrapper {
  flex-direction: column;
  display: flex;
}

@media (min-width: 767px) {
  .formality__wrapper {
    flex-direction: row;
  }
}

.formality__text, .formality__form {
  flex: 50%;
}

.formality__form-list {
  flex-direction: column;
  margin-top: 32px;
  display: flex;
}

@media (min-width: 767px) {
  .formality__form-list {
    margin-top: 0;
  }
}

.formality__form-list input {
  margin-bottom: 16px;
}

@media (min-width: 767px) {
  .formality__form-list input {
    margin-bottom: 22px;
  }
}

@media (min-width: 1200px) {
  .formality__form-list input {
    margin-bottom: 34px;
  }
}

.formality .controls {
  justify-content: center;
}

@media (min-width: 767px) {
  .formality .controls {
    justify-content: flex-start;
  }
}

.home {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home__background {
  background-image: url("bacground.928de6e7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__message {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}

@media (min-width: 767px) {
  .home__message {
    font-size: 20px;
    line-height: 26px;
  }
}

.home h1 {
  display: inline;
}

.home__cta {
  margin-left: 16px;
  display: inline-block;
}

.home__header {
  cursor: pointer;
  width: 60vw;
  margin-bottom: 32px;
}

@media (min-width: 767px) {
  .home__header {
    width: 50vw;
    margin-bottom: 42px;
  }
}

@media (min-width: 992px) {
  .home__header {
    width: 30vw;
    margin-bottom: 42px;
  }
}

@media (min-width: 1200px) {
  .home__header {
    width: 30vw;
    margin-bottom: 42px;
  }
}

.home__header svg {
  height: auto;
  width: 80%;
  margin: 0 auto;
  display: block;
}

.home__heart {
  width: 40px;
}

.home__heart svg {
  width: 100%;
  transform: scaleX(-1);
}

.home__legal {
  font-size: 14px;
  line-height: 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 28%;
}

@media (min-width: 767px) {
  .home__legal {
    font-size: 16px;
    line-height: 18px;
    right: 0;
  }
}

.loader {
  pointer-events: none;
  width: 100px;
  height: 100px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-out;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

.loader--loaded {
  opacity: 0;
}

.loader__lottie {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.personality__text, .personality__choices {
  flex: auto;
}

.personality__heart {
  height: 15px;
  width: 15px;
  display: none;
  position: absolute;
  top: 18px;
  left: 9px;
}

@media (min-width: 767px) {
  .personality__heart {
    top: 34px;
    left: 9px;
  }
}

.personality__choice input:checked + .personality__heart {
  display: block;
}

.personality__choices {
  margin-top: 32px;
}

@media (min-width: 767px) {
  .personality__choices {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .personality__choices {
    margin-bottom: 26px;
  }
}

.personality__wrapper {
  flex-direction: column;
  display: flex;
}

@media (min-width: 767px) {
  .personality__wrapper {
    flex-direction: row;
  }
}

.personality__choice {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.personality__choice input {
  -webkit-appearance: none;
  cursor: pointer;
  height: 33px;
  width: 33px;
  background-color: #0000;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 0;
  position: relative;
}

.personality__choice input:checked {
  border-color: red;
}

.personality__choice label {
  cursor: pointer;
  margin-left: 16px;
  font-size: 20px;
  line-height: 50px;
}

@media (min-width: 767px) {
  .personality__choice label {
    margin-left: 44px;
    font-size: 41px;
    line-height: 82px;
  }
}

.unity__canvas {
  width: 100% !important;
  height: 100vh !important;
}

.unity--loaded:after {
  display: none;
}

.unity:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

#unity-container {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: none;
}

/*# sourceMappingURL=index.a8879be5.css.map */
