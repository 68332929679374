@import '../mixins/responsive';
.personality {

  &__text,
  &__choices {
    flex: 1 1 auto;
  }

  &__heart {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 18px;
    left: 9px;
    display: none;
    @include respond-to('small') {
      top: 34px;
      left: 9px;
    }
  }

  &__choice input:checked + .personality__heart {
    display: block;
  }

  &__choices {
    margin-top: 32px;
    @include respond-to('small') {
      margin-top: 0;
    }
    @include respond-to('large') {
      margin-bottom: 26px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include respond-to('small') {
      flex-direction: row;
    }
  }

  &__choice {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;

    input {
      -webkit-appearance: none;
      background-color: transparent;
      border: 3px solid white;
      border-radius: 50%;
      cursor: pointer;
      height: 33px;
      margin: 0;
      position: relative;
      width: 33px;
      &:checked {
        border-color: rgba(255, 0, 0, 1);
      }
    }

    label {
      cursor: pointer;
      font-size: 20px;
      line-height: 50px;
      margin-left: 16px;
      @include respond-to('small') {
        font-size: 41px;
        line-height: 82px;
        margin-left: 44px;
      }
    }
  }
}