@import '../mixins/responsive';

.home {
 align-items: center;
 display: flex;
 flex-direction: column;
//  height: 100vh;
 justify-content: center;

&__background {
  background-image: url('../assets/bacground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
&__message {
  font-size: 14px;
  line-height: 24px;
  @include respond-to('small') {
    font-size: 20px;
    line-height: 26px;
  }
  text-align: center;
}

 h1 {
   display: inline;
 }

 &__cta {
  display: inline-block;
  margin-left: 16px;
 }

 &__header {
  cursor: pointer;
  margin-bottom : 32px;
  width: 60vw;

  @include respond-to('small') {
    width: 50vw;
    margin-bottom : 42px;
  }

  @include respond-to('medium') {
    width: 30vw;
    margin-bottom : 42px;
  }

  @include respond-to('large') {
    width: 30vw;
    margin-bottom : 42px;
  }
  svg {
    display: block;
    height: auto;
    width: 80%;
    margin: 0 auto;
  }
 }

 &__heart {
   width: 40px;
   svg {
    transform: scaleX(-1);
    width: 100%;
  }
 }
 &__legal {
  position: fixed;
  left: 20px;
  right: 28%;
  bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  @include respond-to('small') {
    font-size: 16px;
    line-height: 18px;
    right: 0;
  }
 }
}